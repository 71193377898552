import { authApi } from '@/api/index.js'
import envData from '@/env.js'
export const env = envData[envData.state];
import store from '@/store'

export default ({
    login(data = { usernameOrEmail: '', password: '' }) {
        return authApi({
            method: 'post',
            url: `/user/login`,
            data: data
        })
    },
    role(auth_token=''){
        return authApi({
            method: 'get',
            url: `/api-user/role`,
            headers: {
                'auth_token': auth_token?auth_token:store.getters['login/auth_token']
            }
        })
    },
    user(userId){
        return authApi({
            method: 'get',
            url: `/user/${userId}/detail`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    users(option = '') {
        return authApi({
            method: 'get',
            url: `/user?${option}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    create(data) {
        return authApi({
            method: 'post',
            url: `/user`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    update(id, data) {
        return authApi({
            method: 'patch',
            url: `/user/${id}`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    delete(id) {
        return authApi({
            method: 'delete',
            url: `/user/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    trash(option=''){
        return authApi({
            method: 'get',
            url: `/user/trash?${option}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    restore(id){
        return authApi({
            method: 'put',
            url: `/user/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    resetPassword(data){
        return authApi({
            method: 'post',
            url: `/user/reset-password`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    changePassword(data){
        return authApi({
            method: 'post',
            url: `/user/change-password`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    userDevice(userId) {
        return authApi({
            method: 'get',
            url: `/user/${userId}/device`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },
    
    userDeviceUpdate(id, data) {
        return authApi({
            method: 'patch',
            url: `/user/device/${id}`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    userDeviceTrash(id){
        return authApi({
            method: 'delete',
            url: `/user/device/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    }
})