<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <v-btn icon dark @click.prevent="back"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
          <span>User Trash</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="options.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="vg_userTrash.rows"
          :loading="loading"
          :server-items-length="vg_userTrash.count"
          :options.sync="options"
          :search.sync="options.search"
          :items-per-page="options.itemsPerPage"
        >
          <template v-slot:item.status="{ item }">
            <v-chip small :color="color[item.status]" dark>{{
              item.status
            }}</v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon v-if="role.restore" color="error" @click.prevent="restoreItem(item)">mdi-reload</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapMutations, mapActions } from "vuex";
import VuetifyTable from '@/helper/table'
import UserApi from '@/api/user'
export default {
  data() {
    return {
      color: {
        registered: "#29B6F6",
        active: "#66BB6A",
        deactivated: "#EF5350",
        blocked: "#BDBDBD"
      },
      loading: true,
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id"
        },
        { text: "Email", value: "email" },
        { text: "Name", value: "display_name" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "action", sortable: false }
      ]
    };
  },
  watch: {
    vg_queryObject: {
      handler: _.debounce(async function() {
        try{
          this.loading = true;
          this.options.view = this.vg_groupRole.view
          await this.va_userTrash(this.optionsToQueryString(this.vg_queryObject));
          this.loading = false;
        }catch(err){
          this.loading = false;
        }
      }, 900),
      deep: true
    },
    // reset page number on search
    'vg_queryObject.search'(){
      this.options.page = 1
    }
  },
  computed: {
    options: {
      get(){
        return this.vg_queryObject
      },
      set(options){
        this.vm_queryObject(options)
      }
    },
    role(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="User")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    ...mapGetters({
      vg_userTrash: "user/listTrash",
      vg_queryObject: "user/query",
      vg_groupRole: "login/role",
    })
  },
  methods: {
    back(){
      this.$router.push({name: 'User'})
    },
    queryStringToOptions(queryString){
      return VuetifyTable.queryStringToVuetifyTableOptions(queryString)
    },
    optionsToQueryString(options){
      return VuetifyTable.vuetifyTableOptionsToQueryString(options)
    },
    async restoreItem(item) {
        try{
          this.spinner(true)
          let {data} = await UserApi.restore(item.id)
          this.snackbar({status: true, message: data.message})
          await this.va_userTrash(this.optionsToQueryString(this.vg_queryObject));
          this.spinner(false)
        }catch(err){
          this.spinner(false)
          console.log(err)
        }
    },
    ...mapMutations({
      vm_queryObject: "user/query"
    }),
    ...mapActions({
      va_userTrash: "user/userTrash"
    })
  }
};
</script>