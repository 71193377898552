<template>
    <v-card>
        <v-card-title class="my-0 py-2 accent">
            <div>Device</div>
            <v-spacer />
            <v-switch
                class="my-0 py-0"
                v-model="active"
                hide-details=""
                ></v-switch>
            <v-icon color="error" @click.prevent="deleteDevice()">mdi-delete</v-icon>
        </v-card-title>
        <v-card-text class="py-3">
            <div>Device: {{detail.os?detail.os:'null'}}</div>
            <div>Browser: {{detail.browser?detail.browser:'null'}}</div>
            <div>Attempts: {{detail.attempts?detail.attempts:'null'}}</div>
            <div>Exceed attempts at: {{detail.exceed_attempts_at?detail.exceed_attempts_at:'null'}}</div>
            <v-btn class="primary" small rounded v-if="detail.attempts || detail.exceed_attempts_at" @click.prevent="resetDevice">Reset</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import User from '@/api/user'
import {mapGetters, mapActions} from 'vuex'

export default {
    props: ['detail'],
    data(){
        return {
            loaded: false,
            active: false,
        }
    },
    mounted(){
        this.active = this.detail.is_active
        setTimeout(() => {
            this.loaded = true
        }, 300)
        
    },
    watch: {
        active(isActive){
            if(this.loaded){
                this.toggleActive(isActive)
            }
        }
    },
    computed: {
        ...mapGetters({
            vg_editObj: 'user/editObj'
        })
    },
    methods: {
        async deleteDevice(){
            try{
                this.spinner(true)
                let {data} = await User.userDeviceTrash(this.detail.id)
                this.va_userDevice(this.vg_editObj.id)
                this.snackbar({status: true, message: data.message})
                this.spinner(false)
            }catch(err){
                this.spinner(false)
            }
        },
        async toggleActive(isActive){
            try{
                this.spinner(true)
                let {data} = await User.userDeviceUpdate(this.detail.id, {is_active: isActive})
                this.$emit('reload')
                this.snackbar({status: true, message: data.message})
                this.spinner(false)
            }catch(err){
                this.spinner(false)
            }
        },
        async resetDevice(){
            try{
                this.spinner(true)
                let {data} = await User.userDeviceUpdate(this.detail.id, {attempts:null, exceed_attempts_at:null})
                this.$emit('reload')
                this.snackbar({status: true, message: data.message})
                this.spinner(false)
            }catch(err){
                this.spinner(false)
            }
        },
        ...mapActions({
            va_userDevice: 'user/userDevice'
        })
    }
}
</script>