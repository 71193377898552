import page401 from '@/views/Page401';

export default [
    {
        name: 'Unauthorized',
        path: '/unauthorized',
        component: page401,
        meta: {
            middleware: {
            },
            nav: {
                addToNav: false,
                navText: '',
                header: '',
                navIcon: '',
            }
        }
    }
]