<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Create Role</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">
                  <v-alert dense outlined type="error" v-if="errors.length>0">
                    <div v-for="(error, ind) in errors" :key="ind">{{error}}</div>
                  </v-alert>

                  <v-select
                    class="mt-6"
                    v-model="role.app_id"
                    :rules="rules.appId"
                    :items="apps"
                    item-text="name"
                    item-value="id"
                    label="Application*"
                    dense
                  ></v-select>

                  <v-text-field
                    class="mt-2"
                    v-model="role.model"
                    :rules="rules.model"
                    hint="Please enter model name"
                    label="Model*"
                  ></v-text-field>

                  <v-switch
                    v-model="role.add"
                    :label="`Add: ${role.add.toString()}`"
                  ></v-switch>

                  <v-switch
                    v-model="role.update"
                    :label="`Update: ${role.update.toString()}`"
                  ></v-switch>

                  <v-switch
                    v-model="role.delete"
                    :label="`Delete: ${role.delete.toString()}`"
                  ></v-switch>

                  <v-switch
                    v-model="role.trashes"
                    :label="`Trashes: ${role.trashes.toString()}`"
                  ></v-switch>

                  <v-switch
                    v-model="role.restore"
                    :label="`Restore: ${role.restore.toString()}`"
                  ></v-switch>

                  <v-switch
                    v-model="role.erase"
                    :label="`Erase: ${role.erase.toString()}`"
                  ></v-switch>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import Role from '@/api/role'
import App from '@/api/app'
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      errors: [],
      role: {
        app_id: '',
        role_group_id: null,
        model: "",
        add: false,
        update: false,
        delete: false,
        trashes: false,
        restore: false,
        erase: false
      },
      rules: {
        appId: [v => !!v || "Application name is required"],
        model: [v => !!v || "Model name is required"]
      },
      apps: [],
      types: ["true", "false"],
    };
  },
  async mounted(){
    let {data: {payload}} = await App.list()
    this.apps = payload
  },
  computed:{
    ...mapGetters({
      vg_editObj: 'role_group/editObj'
    })
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          this.role.role_group_id = this.$route.params.role_group_id
          let {data} = await Role.create(this.role)
          this.snackbar({status: true, message: data.message})
          this.spinner(false)
          this.$router.push({name: 'Role', params: {role_group_id: this.$route.params.role_group_id}})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message})
          this.errors = err.response.data.payload
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Role", params: {role_group_id: this.$route.params.role_group_id} });
    }
  }
};
</script>