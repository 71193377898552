import User from '@/views/user';
import UserEdit from '@/views/user/edit'
import UserCreate from '@/views/user/create'
import UserTrash from '@/views/user/trash'
import UserDevice from '@/views/user/device'
import Profile from '@/views/user/profile';

export default [
    {
        name: 'User',
        path: '/user',
        component: User,
        meta: {
            nav: {
                addToNav: true,
                navText: 'User',
                header: 'User',
                navIcon: 'mdi-account',
            }
        }
    },
    {
        name: 'UserCreate',
        path: '/user/create',
        component: UserCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: 'User > Create',
                navIcon: 'mdi-account',
            }
        }
    },
    {
        name: 'UserEdit',
        path: '/user/edit/:user_id',
        component: UserEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: 'User > Edit',
                navIcon: 'mdi-account',
            }
        }
    },
    {
        name: 'UserTrash',
        path: '/user/trash',
        component: UserTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: 'User > Trash',
                navIcon: 'mdi-account',
            }
        }
    },
    {
        name: 'UserDevice',
        path: '/user/device',
        component: UserDevice,
        meta: {
            nav: {
                addToNav: false,
                navText: 'User > Device',
                navIcon: 'mdi-account',
            }
        }
    },
    {
        name: 'Profile',
        path: '/profile',
        component: Profile,
        meta: {
            nav: {
                addToNav: false,
                navText: 'Profile',
                header: 'Profile',
                navIcon: '',
            }
        }
    }
]