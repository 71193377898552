var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mt-8 mb-4",attrs:{"shaped":"","elevation":"6"}},[_c('v-card',{staticClass:"primary white--text header-bar-content",staticStyle:{"margin-top":"10px"}},[_c('v-card-title',{staticClass:"px-6"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white"},on:{"click":_vm.goToApi}},[_vm._v("mdi-arrow-left")]),_c('span',[_vm._v("API User")]),_c('v-spacer'),(_vm.role.add)?_c('v-btn',{attrs:{"color":"white black--text","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.createApiUser.apply(null, arguments)}}},[_vm._v("Add")]):_vm._e(),(_vm.role.trashes)?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"red darken-4 grey--text text--lighten-2 font-weight-bold","rounded":""},on:{"click":_vm.trashedApiUser}},[_vm._v("Trash")]):_vm._e()],1)],1),_c('v-card-text',{staticClass:"pt-0 pb-4 text-center"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.vg_api_users,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.api",fn:function(ref){
var item = ref.item;
return [(item.api)?_c('span',[_vm._v(_vm._s((item.api.name)))]):_vm._e()]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('span',[_vm._v(_vm._s((item.user.email)))]):_vm._e()]}},{key:"item.role_group",fn:function(ref){
var item = ref.item;
return [(item.role_group)?_c('span',[_vm._v(_vm._s((item.role_group.name)))]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.role.update)?_c('v-icon',{staticClass:"ml-2 mr-1",attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]):_vm._e(),(_vm.role.delete)?_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }