import page404 from '@/views/Page404';
import Auth from '@/middleware/auth';

export default [
    {
        name: 'Page404',
        path: '*',
        component: page404,
        meta: {
            middleware: {
                ignore: Auth
            },
            nav: {
                addToNav: false,
                navText: '',
                header: '',
                navIcon: '',
            }
        }
    }
]