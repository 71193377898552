import Dashboard from '@/views/dashboard';

export default [
    {
        name: 'Dashboard',
        path: '/dashboard',
        component: Dashboard,
        meta: {
            nav: {
                addToNav: true,
                navText: 'Dashboard',
                header: 'Dashboard',
                navIcon: 'mdi-home',
            }
        }
    }
]