import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'
import env from '@/env.js'

import login from './modules/login'
import user from './modules/user'
import api from './modules/api'
import api_user from './modules/api_user'
import app from './modules/app'
import role_group from './modules/role_group'
import role from './modules/role'
import log from './modules/log'

export const config = env[env.state];
var ls = new SecureLS({ isCompression: false, encryptionSecret: config.app_key })

Vue.use(Vuex)

export default  new Vuex.Store({
    plugins: [createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })],
    modules: {login, user, api, api_user, app, role_group, role, log}
})