import RoleGroup from '@/views/role-group';
import RoleGroupCreate from '@/views/role-group/create'
import RoleGroupEdit from '@/views/role-group/edit'
import RoleGroupTrash from '@/views/role-group/trash'
import Role from '@/views/role';
import RoleCreate from '@/views/role/create'
import RoleEdit from '@/views/role/edit'
import RoleEditAll from '@/views/role/edit-all'
import RoleTrash from '@/views/role/trash'

export default [
    {
        name: 'RoleGroup',
        path: '/role/group',
        component: RoleGroup,
        meta: {
            nav: {
                addToNav: true,
                navText: 'Role',
                header: 'Role > Group',
                navIcon: 'mdi-clipboard-check',
            }
        }
    },
    {
        name: 'Role',
        path: ':role_group_id/role',
        component: Role,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Role',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'RoleGroupCreate',
        path: '/role/group/create',
        component: RoleGroupCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Role > Group > Create',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'RoleCreate',
        path: ':role_group_id/role/create',
        component: RoleCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Role > Create',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'RoleGroupEdit',
        path: '/role/group/edit',
        component: RoleGroupEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Role > Group > Edit',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'RoleEdit',
        path: ':role_group_id/role/edit',
        component: RoleEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Role > Edit',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'RoleEditAll',
        path: ':role_group_id/role/edit-all',
        component: RoleEditAll,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Role > Edit > All',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'RoleGroupTrash',
        path: '/role/group/trash',
        component: RoleGroupTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Role > Group > Trash',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'RoleTrash',
        path: ':role_group_id/role/trash',
        component: RoleTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Role > Trash',
                navIcon: 'mdi-api',
            }
        }
    }
]