<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <v-btn icon dark @click.prevent="back"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
          <span>API User Trash</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="vg_trashedUserApis"
          :loading="loading"
          :search="search"
        >
          <template v-slot:item.api="{ item }">
            <span v-if="item.api">{{ (item.api.name) }}</span>
          </template>
          <template v-slot:item.user="{ item }">
            <span v-if="item.user">{{ (item.user.email) }}</span>
          </template>
          <template v-slot:item.role_group="{ item }">
            <span v-if="item.role_group">{{ (item.role_group.name) }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon v-if="role.restore" color="primary" class="ml-2 mr-1" @click.prevent="restoreItem(item)"
              >mdi-reload</v-icon
            >
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import ApiUser from '@/api/api_user'
export default {
  data() {
    return {
      color: {
        true: "#66BB6A",
        false: "#EF5350",
      },
      loading: true,
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id"
        },
        { text: "API Name", value: "api", sortable: false },
        { text: "Email", value: "user", sortable: false},
        { text: "Role Group", value: "role_group", sortable: false},
        { text: "Ip rule", value: "ip_rule" },
        { text: "Actions", value: "action", sortable: false }
      ],
      search: ''
    };
  },
  async mounted(){
    try{
      this.loading = true;
      await this.va_trahsedUserApis();
      this.loading = false;
    }catch(err){
      this.loading = false;
    }
  },
  computed: {
    role(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="Api User")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    ...mapGetters({
      vg_groupRole: "login/role",
      vg_trashedUserApis: "api_user/listTrash"
    })
  },
  methods: {
    back(){
      this.$router.push({name: 'ApiUser'})
    },
    async restoreItem(item) {
      let self = this
      this.confirm('Are you sure to restore this?').then(async () => {
        try{
          self.spinner(true)
          let {data} = await ApiUser.restore(item.id)
          this.snackbar({status: true, message: data.message})
          await this.va_trahsedUserApis()
          self.spinner(false)
        }catch(err){
          self.spinner(false)
          console.log(err)
        }
      })
    },
    ...mapMutations({
      vm_editObj: "api_user/editObj",
      vm_queryObject: "api_user/query"
    }),
    ...mapActions({
      va_trahsedUserApis: "api_user/userTrash"
    })
  }
};
</script>