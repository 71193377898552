import RoleGroup from '@/api/role_group'

export default {
    namespaced: true,
    state: {
        list: [],
        listTrash: [],
        addPop: false,
        editPop: false,
        editObj: {}
    },
    getters: {
        list: state => state.list,
        listTrash: state => state.listTrash,
        addPop: state => state.addPop,
        editPop: state => state.editPop,
        editObj: state => state.editObj
    },
    mutations: {
        list(state, data) {
            state.list = data
        },
        listTrash(state, data){
            state.listTrash =  data
        },
        addPop(state, data) {
            state.addPop = data
            // If add is on edit is false
            state.editPop = false
        },
        editPop(state, data) {
            state.editPop = data
            // If Edit is on add is false
            state.addPop = false
        },
        editObj(state, data) {
            state.editObj = data
        },
        reset(state) {
            state.list = []
            state.listTrash = []
            state.addPop = false
            state.editPop = false
            state.editObj = {}
        }
    },
    actions: {
        async list({ commit }, view) {
            try{
                let {data} = await RoleGroup.list(view);
                commit("list", data.payload);
            }catch(err){
                commit("list", [])
            }
        },
        async trashList({commit}, view){
            try{
                let {data} = await RoleGroup.trash(view);
                commit("listTrash", data.payload);
            }catch(err){
                commit("listTrash", [])
            }
        }
    }
};