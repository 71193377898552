<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <span>API</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn v-if="role.add" color="white black--text" rounded @click.prevent="createApi"
            >Add</v-btn
          >
          <v-btn
            v-if="role.trashes"
            class="ml-4"
            color="red darken-4 grey--text text--lighten-2 font-weight-bold"
            rounded
            @click="trashedApi"
            >Trash</v-btn
          >
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="vg_apis"
          :loading="loading"
          :search="search"
        >
          <template v-slot:item.user="{ item }">
            <span v-if="item.user">{{ (item.user.email) }}</span>
          </template>
          <template v-slot:item.is_active="{ item }">
            <v-chip small :color="color[item.is_active]" dark>{{
              (item.is_active)?'Active':'Inactive'
            }}</v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon color="primary" class="ml-2 mr-1" @click.prevent="copyTokenToClipboard(item)"
              >mdi-clipboard-outline</v-icon
            >
            <v-icon v-if="role.update" color="primary" class="ml-2 mr-1" @click.prevent="apiUser(item)"
              >mdi-account-check</v-icon
            >
            <v-icon v-if="role.update" color="primary" class="ml-2 mr-1" @click.prevent="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon v-if="role.delete" color="error" @click.prevent="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import Api from '@/api/api'
export default {
  data() {
    return {
      color: {
        true: "#66BB6A",
        false: "#EF5350",
      },
      loading: true,
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id"
        },
        { text: "Name", value: "name"},
        { text: "Type", value: "type" },
        { text: "Active", value: "is_active" },
        { text: "API Type", value: "api_type" },
        { text: "Expire on", value: "expire_on" },
        { text: "Email", value: "user", sortable: false},
        { text: "Actions", value: "action", sortable: false }
      ],
      search: '',
      application: []
    };
  },
  async mounted(){
    try{
      this.loading = true;
      await this.va_apis();
      this.loading = false;
    }catch(err){
      this.loading = false;
    }
  },
  computed: {
    role(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="Api")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    ...mapGetters({
      vg_groupRole: "login/role",
      vg_apis: "api/list"
    })
  },
  methods: {
    copyTokenToClipboard(item){
      navigator.clipboard.writeText(item.api_token)
      this.snackbar({status: true, message: 'Copied to clipboard', color: 'success'})
    },
    createApi() {
      this.$router.push({ name: "ApiCreate" })
    },
    editItem(item) {
      this.vm_editObj(item);
      this.$router.push({ name: "ApiEdit" })
    },
    apiUser(item){
      this.vm_editObj(item);
      this.$router.push({name: "ApiUser", params: {api_id: item.id}})
    },
    trashedApi() {
      this.$router.push({ name: "ApiTrash" })
    },
    async deleteItem(item) {
      let self = this
      this.confirm('Are you sure to delete this?').then(async () => {
        try{
          self.spinner(true)
          let {data} = await Api.delete(item.id)
          this.snackbar({status: true, message: data.message})
          await this.va_apis()
          self.spinner(false)
        }catch(err){
          self.spinner(false)
          console.log(err)
        }
      })
    },
    ...mapMutations({
      vm_editObj: "api/editObj",
      vm_queryObject: "api/query"
    }),
    ...mapActions({
      va_apis: "api/apis"
    })
  }
};
</script>