import { authApi } from '@/api/index.js'
import store from '@/store'

export default ({
    list(apiId) {
        return authApi({
            method: 'get',
            url: `/api-user/${apiId}?view=${store.getters['login/role'].view}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    create(data) {
        return authApi({
            method: 'post',
            url: `/api-user`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    update(id, data) {
        return authApi({
            method: 'patch',
            url: `/api-user/${id}`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    delete(id) {
        return authApi({
            method: 'delete',
            url: `/api-user/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    trash(){
        return authApi({
            method: 'get',
            url: `/api-user/trash?view=${store.getters['login/role'].view}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    restore(id){
        return authApi({
            method: 'put',
            url: `/api-user/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    }
})