<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <v-btn icon dark @click.prevent="back"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
          <span>Role</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn color="white black--text" rounded @click.prevent="create"
            >Add</v-btn
          >
          <v-btn class="ml-4" color="white black--text" rounded @click.prevent="editAll"
            >Edit</v-btn
          >
          <v-btn
            class="ml-4"
            color="red darken-4 grey--text text--lighten-2 font-weight-bold"
            rounded
            @click.prevent="trashed"
            >Trash</v-btn
          >
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="list"
          :loading="loading"
          :search="search"
        >
          <template v-slot:item.action="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="primary" class="ml-2 mr-1" @click.prevent="editItem(item)">mdi-pencil</v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="error" @click.prevent="deleteItem(item)">mdi-delete</v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import RoleApi from '@/api/role'
export default {
  data() {
    return {
      loading: true,
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id"
        },
        { text: "Model", value: "model" },
        { text: "Add", value: "add" },
        { text: "Update", value: "update" },
        { text: "Trashes", value: "trashes" },
        { text: "Delete", value: "delete" },
        { text: "Restore", value: "restore" },
        { text: "Erase", value: "erase" },
        { text: "Actions", value: "action", sortable: false }
      ],
      search: ''
    };
  },
  async mounted(){
    try{
      this.loading = true;
      await this.va_role(this.$route.params.role_group_id);
      this.loading = false;
    }catch(err){
      this.loading = false;
    }
  },
  computed: {
    ...mapGetters({
      list: "role/list"
    })
  },
  methods: {
    back(){
      this.$router.push({name: 'RoleGroup'})
    },
    create() {
      this.$router.push({ name: "RoleCreate" })
    },
    editAll() {
      this.$router.push({ name: "RoleEditAll" })
    },
    editItem(item){
      this.vm_editObj(item)
      this.$router.push({name: "RoleEdit"})
    },
    trashed() {
      this.$router.push({ name: "RoleTrash" })
    },
    async deleteItem(item) {
      let self = this
      this.confirm('Are you sure to delete this?').then(async () => {
        try{
          self.spinner(true)
          let {data} = await RoleApi.delete(item.id)
          this.snackbar({status: true, message: data.message})
          await this.va_role(this.$route.params.role_group_id)
          self.spinner(false)
        }catch(err){
          self.spinner(false)
          console.log(err)
        }
      })
    },
    ...mapMutations({
      vm_editObj: "role/editObj",
      vm_queryObject: "role/query"
    }),
    ...mapActions({
      va_role: "role/list"
    })
  }
};
</script>