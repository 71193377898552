<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Create Api</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">
                  <v-alert dense outlined type="error" v-if="errors.length>0">
                    <div v-for="(error, ind) in errors" :key="ind">{{error}}</div>
                  </v-alert>

                  <v-text-field
                    class="mt-2"
                    v-model="api.name"
                    :rules="rules.name"
                    hint="Please enter name"
                    label="Name*"
                  ></v-text-field>

                  <v-select
                    class="mt-6"
                    v-model="api.type"
                    :rules="rules.type"
                    :items="types"
                    label="Type*"
                    dense
                  ></v-select>

                  <v-select
                    class="mt-6"
                    v-model="api.is_active"
                    :rules="rules.is_active"
                    :items="activeTypes"
                    label="Active*"
                    dense
                  ></v-select>

                  <v-autocomplete
                    v-model="api.user_id"
                    :rules="rules.user_id"
                    :items="users"
                    :loading="isLoading"
                    :search-input.sync="searchUser"
                    hide-no-data
                    hide-selected
                    item-text="email"
                    item-value="id"
                    label="User*"
                    placeholder="Type email address"
                  ></v-autocomplete>

                  <v-select
                    class="mt-6"
                    v-model="api.app_id"
                    :rules="rules.app_id"
                    :items="appTypes"
                    item-text="name"
                    item-value="id"
                    label="Application*"
                    dense
                  ></v-select>

                  <v-select
                    class="mt-6"
                    v-model="api.api_type"
                    :rules="rules.api_type"
                    :items="apiTypes"
                    label="API Type*"
                    dense
                  ></v-select>

                  <v-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="api.expire_on"
                        :rules="rules.expire_on"
                        label="Expire on*"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="api.expire_on"
                      @input="datePicker = false"
                    ></v-date-picker>
                  </v-menu>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from 'lodash'
import Api from '@/api/api'
import App from '@/api/app'
import User from '@/api/user'

export default {
  data() {
    return {
      errors: [],
      api: {
        name: "",
        type: "",
        is_active: "",
        user_id: "",
        app_id: "",
        expire_on: "",
        api_type: ""
      },
      rules: {
        name: [v => !!v || "Name is required"],
        type: [v => !!v || "Type is required"],
        is_active: [v => !!v || "Is active is required"],
        user_id: [v => !!v || "User is required"],
        app_id: [v => !!v || "Application is required"],
        expire_on: [v => !!v || "Expire on is required"],
        api_type: [v => !!v || "Api type is required"]
      },
      types: ["RkApp", "partner", "client", "other"],
      appTypes: [],
      apiTypes: ["public", "private"],
      activeTypes: ["true", "false"],
      users: [],
      isLoading: false,
      searchUser: '',
      datePicker: false
    };
  },
  async mounted(){
    this.spinner(true)
    let {data} = await App.list()
    this.appTypes = data.payload
    this.spinner(false)
  },
  watch: {
    searchUser: {
      handler: _.debounce(async function(val){
        try{
          if(val){
            this.isLoading = true
            let {data: {payload}} = await User.users(`search=${val}&self=true&page=1`)
            this.users = payload.rows
            this.isLoading = false
          }
        }catch(err){
          console.log(err)
          this.isLoading = true
        }
      }, 900),
      deep: true
    },
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await Api.create(this.api)
          this.snackbar({status: true, message: data.message})
          this.spinner(false)
          this.$router.push({name: 'Api'})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message})
          this.errors = err.response.data.payload
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "Api" });
    }
  }
};
</script>