<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <span>Log</span>
          <v-spacer></v-spacer>

          <v-autocomplete
            v-if="vg_groupRole.view=='all'"
            dark
            class="mr-5"
            v-model="userId"
            :items="users"
            :loading="isLoading"
            :search-input.sync="searchUser"
            hide-no-data
            hide-selected
            item-text="email"
            item-value="id"
            label="User"
            hide-details
            style="width:0px;"
          ></v-autocomplete>
          <v-select
            dark
            class="mr-9"
            v-model="options.app_id"
            :items="vg_app"
            item-text="name"
            item-value="id"
            label="Application"
            hide-details
            style="width:0px;"
          ></v-select>
          <v-text-field
            dark
            class="mr-5"
            v-model="options.search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            style="width:0px;"
          ></v-text-field>
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="logs.rows"
          :loading="loading"
          :server-items-length="logs.count"
          :options.sync="options"
          :search.sync="options.search"
          :items-per-page="options.itemsPerPage"
        >
          <template v-slot:item.app="{ item }">
            {{ (item.application.name) }}
          </template>
          <template v-slot:item.user="{ item }">
            {{ (item.user.email) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import _ from 'lodash'
import Log from '@/api/log'
import User from '@/api/user'
import { mapActions, mapGetters, mapMutations } from "vuex";
import VuetifyTable from '@/helper/table'
export default {
  data() {
    return {
      logs: [],
      loading: true,
      users: [],
      userId: '',
      searchUser: '',
      isLoading: false,
      headers: [
        {
          text: "Id",
          align: "left",
          sortable: false,
          value: "id"
        },
        { text: "User", value: "user", sortable: false },
        { text: "App", value: "app", sortable: false },
        { text: "Country", value: "country"},
        { text: "City", value: "city"},
        { text: "IP Address", value: "ip_address"},
        { text: "Model", value: "model" },
        { text: "Action", value: "action" },
      ]
    };
  },
  mounted(){
    this.va_app()
  },
  watch: {
    vg_queryObject: {
      handler: _.debounce(async function() {
        try{
          this.loading = true;
          this.options.view = this.vg_groupRole.view
          await this.load(this.optionsToQueryString(this.vg_queryObject));
          this.loading = false;
        }catch(err){
          this.loading = false;
        }
      }, 900),
      deep: true
    },
    searchUser: {
      handler: _.debounce(async function(val){
        try{
          if(val && val!='All'){
            this.isLoading = true
            let {data: {payload}} = await User.users(`search=${val}&self=true&page=1&items=4`)
            this.users = [{user_id: "", email: 'All'}, ...payload.rows]
            this.isLoading = false
          }
        }catch(err){
          console.log(err)
          this.users = [{user_id: "", email: 'All'}]
          this.isLoading = true
        }
      }, 900),
      deep: true
    },
    userId(val){
      if(val && val!='All'){
        this.options.user_id = val
      }else{
        this.options.user_id = null
      }
    },
    // reset page number on search
    'vg_queryObject.search'(){
      this.options.page = 1
    }
  },
  computed: {
    options: {
      get(){
        return this.vg_queryObject
      },
      set(options){
        this.vm_queryObject(options)
      }
    },
    ...mapGetters({
      vg_groupRole: "login/role",
      vg_queryObject: "log/query",
      vg_app: "log/app"
    })
  },
  methods: {
    async load(query){
      let {data} = await Log.list(query)
      this.logs = data.payload
    },
    queryStringToOptions(queryString){
      return VuetifyTable.queryStringToVuetifyTableOptions(queryString)
    },
    optionsToQueryString(options){
      return VuetifyTable.vuetifyTableOptionsToQueryString(options)
    },
    ...mapMutations({
      vm_queryObject: "log/query"
    }),
    ...mapActions({
      va_app: "log/app"
    })
  }
};
</script>