<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <v-btn icon dark @click.prevent="back"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
          <span>User Devices</span>
          <v-spacer></v-spacer>
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-row>
            <div class="mt-5 ml-5 pb-5" v-if="vg_userDevice.length===0">No Device found.</div>
            <v-col cols="12" sm="6" md="4" lg="3" v-for="(device, index) in vg_userDevice" :key="index">
                <device-item :detail="device" @reload="load()"></device-item>
            </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DeviceItem from './devices/device-item'
import {mapGetters, mapActions} from 'vuex'

export default {
    components: {
        DeviceItem
    },
    data() {
        return {
            status: false
        }
    },
    mounted(){
        this.load()
    },
    computed: {
        ...mapGetters({
            vg_login: 'login/user',
            vg_editObj: 'user/editObj',
            vg_userDevice: 'user/userDevice'
        })
    },
    methods: {
      back(){
        this.$router.push({name: 'User'})
      },
      load(){
        try{
            this.spinner(true)
            this.va_userDevice(this.vg_editObj.id)
            this.spinner(false)
        }catch(err){
            console.log(err)
            this.spinner(false)
        }
      },
      ...mapActions({
          va_userDevice: 'user/userDevice'
      })
    }

}
</script>
