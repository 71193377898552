import Log from '@/views/log';

export default [
    {
        name: 'Log',
        path: '/log',
        component: Log,
        meta: {
            nav: {
                addToNav: true,
                navText: 'Log',
                header: 'Log',
                navIcon: 'mdi-math-log',
            }
        }
    }
]