import {parse, stringify} from 'query-string'

export default {
    queryStringToVuetifyTableOptions(queryString){
        if(!queryString) return 

        let url = parse(queryString)
        let vuetifyOptions = {
            page: parseInt(url.page),
            itemsPerPage: parseInt(url.items),
            sortBy: [url.sort],
            sortDesc: [url.asc==='true'],
            app_id: url.app_id,
            user_id: url.user_id,
            view: url.vuew
        } 
        return vuetifyOptions
    },

    vuetifyTableOptionsToQueryString(tableOptionObject){
        if(!tableOptionObject) return

        let queryStringObject = {
            page: tableOptionObject.page,
            items: tableOptionObject.itemsPerPage,
            sort: tableOptionObject.sortBy[0],
            asc: tableOptionObject.sortDesc[0],
            app_id: tableOptionObject.app_id,
            user_id: tableOptionObject.user_id,
            view: tableOptionObject.view
        }

        if(tableOptionObject.search){
            queryStringObject.search = tableOptionObject.search
        }

        return stringify(queryStringObject)
    }
}