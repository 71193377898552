import { authApi } from '@/api/index.js'
import store from '@/store'

export default ({
    list(query) {
        return authApi({
            method: 'get',
            url: `/log?${query}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    }
})