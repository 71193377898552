import App from '@/views/app';
import AppCreate from '@/views/app/create'
import AppEdit from '@/views/app/edit'
import AppTrash from '@/views/app/trash'

export default [
    {
        name: 'App',
        path: '/app',
        component: App,
        meta: {
            nav: {
                addToNav: true,
                navText: 'Application',
                header: 'Application',
                navIcon: 'mdi-application',
            }
        }
    },
    {
        name: 'AppCreate',
        path: '/app/create',
        component: AppCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'App > Create',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'AppEdit',
        path: '/app/edit',
        component: AppEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'App > Edit',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'AppTrash',
        path: '/app/trash',
        component: AppTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'App > Trash',
                navIcon: 'mdi-api',
            }
        }
    }
]