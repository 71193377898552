<template>
  <v-container>
    <v-card shaped elevation="6" class="mt-8 mb-4">
      <v-card
        class="primary white--text header-bar-content"
        style="margin-top: 10px"
      >
        <v-card-title class="px-6">
          <span>Application</span>
          <v-spacer></v-spacer>
          <v-text-field
            dark
            class="mr-9"
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <v-btn v-if="role.add" color="white black--text" rounded @click.prevent="createApi"
            >Add</v-btn
          >
          <v-btn
            v-if="role.trashes"
            class="ml-4"
            color="red darken-4 grey--text text--lighten-2 font-weight-bold"
            rounded
            @click="trashedApi"
            >Trash</v-btn
          >
        </v-card-title>
      </v-card>
      <v-card-text class="pt-0 pb-4 text-center">
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="vg_apps"
          :loading="loading"
          :search="search"
        >
          <template v-slot:item.action="{ item }">
            <v-icon v-if="role.update" color="primary" class="ml-2 mr-1" @click.prevent="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon v-if="role.delete" color="error" @click.prevent="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import AppApi from '@/api/app'
export default {
  data() {
    return {
      loading: true,
      headers: [
        {
          text: "Id",
          align: "left",
          value: "id"
        },
        { text: "Name", value: "name" },
        { text: "Actions", value: "action", sortable: false }
      ],
      search: '',
      application: []
    };
  },
  async mounted(){
    try{
      this.loading = true;
      await this.va_apps();
      this.loading = false;
    }catch(err){
      this.loading = false;
    }
  },
  computed: {
    role(){ 
      let findRole = this.vg_groupRole.role.filter(each => each.model=="Application")
      return (findRole && findRole.length>0)?findRole[0]:null
    },
    ...mapGetters({
      vg_groupRole: "login/role",
      vg_apps: "app/list"
    })
  },
  methods: {
    createApi() {
      this.$router.push({ name: "AppCreate" })
    },
    editItem(item) {
      this.vm_editObj(item);
      this.$router.push({ name: "AppEdit" })
    },
    trashedApi() {
      this.$router.push({ name: "AppTrash" })
    },
    async deleteItem(item) {
      let self = this
      this.confirm('Are you sure to delete this?').then(async () => {
        try{
          self.spinner(true)
          let {data} = await AppApi.delete(item.id)
          this.snackbar({status: true, message: data.message})
          await this.va_apps()
          self.spinner(false)
        }catch(err){
          self.spinner(false)
          console.log(err)
        }
      })
    },
    ...mapMutations({
      vm_editObj: "app/editObj",
      vm_queryObject: "app/query"
    }),
    ...mapActions({
      va_apps: "app/apps"
    })
  }
};
</script>