export default {
    standardPhoneFormat(phoneNumber){
        if(!phoneNumber) return

        let x = phoneNumber.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    nepaliPhoneFormat(phoneNumber){
        if(!phoneNumber) return

        let x = phoneNumber.replace(/\D/g, '').match(/(\d{0,4})(\d{0,2})(\d{0,4})/);
        return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    flatPhoneFormat(phoneNumber){
        return phoneNumber.replace(/[^0-9.]/g, '');
    },
    censorPhone(phoneNumber){
        if(!phoneNumber) return

        let cleaned = ('' + phoneNumber).replace(/\D/g, '')
        let match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{2})(\d{2})$/)
        if (match) {
            return ['+',match[1], '-', match[2], '-**-*', match[5]].join('')
        } else {
            return
        }
    },
}