import AppApi from '@/api/app'

export default {
    namespaced: true,
    state: {
        app: [],
        query: {
            search: '',
            itemsPerPage: 50
        }
    },
    getters: {
        app: state => state.app,
        query: state => state.query
    },
    mutations: {
        app(state, data){
            state.app = data
        },
        query(state, data) {
            state.query = data
        }
    },
    actions: {
        async app({ commit }, view) {
            try{
                let {data} = await AppApi.idName(view);
                commit("app", [{id:null, name:'All'}, ...data.payload]);
            }catch(err){
                commit("app", [])
            }
        }
    }
}