import Api from '@/views/api';
import ApiCreate from '@/views/api/create'
import ApiEdit from '@/views/api/edit'
import ApiTrash from '@/views/api/trash'

export default [
    {
        name: 'Api',
        path: '/api',
        component: Api,
        meta: {
            nav: {
                addToNav: true,
                navText: 'Api',
                header: 'Api',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'ApiCreate',
        path: '/api/create',
        component: ApiCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Api > Create',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'ApiEdit',
        path: '/api/edit',
        component: ApiEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Api > Edit',
                navIcon: 'mdi-api',
            }
        }
    },
    {
        name: 'ApiTrash',
        path: '/api/trash',
        component: ApiTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Api > Trash',
                navIcon: 'mdi-api',
            }
        }
    }
]