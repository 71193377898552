<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Edit Role</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
                <v-row v-for="(role, ind) in roles" :key="ind">
                    <v-col cols="3" md="3">
                        <v-text-field
                          v-model="role.model"
                          label="Model*"
                          disabled
                        ></v-text-field>
                        <v-select
                          v-model="role.app_id"
                          :items="apps"
                          item-text="name"
                          item-value="id"
                          label="Application*"
                          disabled
                          dense
                        ></v-select>
                    </v-col>
                    <v-col cols="3" md="3">
                        <v-switch
                            v-model="role.add"
                            :label="`Add`"
                            hide-details
                        ></v-switch>
                        <v-switch
                            v-model="role.update"
                            :label="`Update`"
                            hide-details
                        ></v-switch>
                    </v-col>
                    <v-col cols="3" md="3">
                        <v-switch
                            v-model="role.trashes"
                            :label="`Trashes`"
                            hide-details
                        ></v-switch>
                        <v-switch
                            v-model="role.delete"
                            :label="`Delete`"
                            hide-details
                        ></v-switch>
                    </v-col>
                    <v-col cols="3" md="3">
                        <v-switch
                            v-model="role.restore"
                            :label="`Restore`"
                            hide-details
                        ></v-switch>
                        <v-switch
                            v-model="role.erase"
                            :label="`Erase`"
                            hide-details
                        ></v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn color="success" class="mt-5 px-5" right rounded @click="save">Save</v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from 'lodash'
import Role from '@/api/role'
import App from '@/api/app'
import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      errors: [],
      roles: [],
      apps: []
    };
  },
  async mounted(){
      this.spinner(true)
      let {data: {payload}} = await App.list()
      this.apps = payload
      for(let ind in this.vg_list){
        let selected = _.pick(this.vg_list[ind], [
            "id",
            "app_id",
            "role_group_id",
            "model",
            "list",
            "add",
            "update",
            "delete",
            "trashes",
            "restore",
            "erase"
        ]);
        this.roles.push(selected)
      }
      this.spinner(false)
  },
  computed:{
    ...mapGetters({
      vg_list: 'role/list'
    })
  },
  methods: {
    async save() {
        try{
          this.spinner(true)
          for(let ind in this.roles){
            await Role.update(this.roles[ind].id, this.roles[ind])
          }
          this.snackbar({status: true, message: "Role updated successfully"})
          this.spinner(false)
          this.$router.push({name: 'Role', params: {role_group_id: this.$route.params.role_group_id}})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message})
          this.errors = err.response.data.payload
          this.spinner(false)
        }
    },
    cancel() {
      this.$router.push({ name: "Role", params: {role_group_id: this.$route.params.role_group_id} });
    }
  }
};
</script>