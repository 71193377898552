<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <v-btn icon dark @click.prevent="back"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
                <span>Update User</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">

                  <v-text-field v-model="user.email" label="Email" disabled></v-text-field>

                  <v-text-field 
                    v-model="user.display_name" 
                    :rules="rules.displayName"
                    label="Display As*"
                  ></v-text-field>

                  <v-select
                    class="mt-6"
                    v-model="user.status"
                    :items="statusOptions"
                    label="Status"
                    dense
                  ></v-select>

                  <v-text-field
                    class="mt-2"
                    v-model="user.first_name"
                    :rules="rules.first_name"
                    hint="Please enter first name"
                    counter="15"
                    label="First Name"
                  ></v-text-field>

                  <v-text-field
                    class="mt-4"
                    v-model="user.middle_name"
                    :rules="rules.middle_name"
                    hint="Please enter middle name"
                    counter="15"
                    label="Middle Name"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="user.last_name"
                    :rules="rules.last_name"
                    hint="Please enter last name"
                    counter="15"
                    label="Last Name"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="phone"
                    hint="Please enter phone number"
                    counter="14"
                    label="Phone"
                    @keyup.prevent="formatPhoneNumber"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="user.youtube"
                    :rules="rules.youtube"
                    hint="Please enter youtube url"
                    counter="50"
                    label="Youtube"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="user.facebook"
                    :rules="rules.facebook"
                    hint="Please enter facebook url"
                    counter="50"
                    label="Facebook"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="user.instagram"
                    :rules="rules.instagram"
                    hint="Please enter instagram url"
                    counter="50"
                    label="Instagram"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="user.tiktok"
                    :rules="rules.tiktok"
                    hint="Please enter tiktok url"
                    counter="50"
                    label="Tiktok"
                  ></v-text-field>

                  <v-text-field
                    class="mt-2"
                    v-model="formatDateTime"
                    label="Created At"
                    disabled
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from "lodash";
import dateHelper from '@/helper/date'
import phoneHelper from '@/helper/phone'
import User from '@/api/user'
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      user: {
        status: "",
        display_name: "",
        email: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        phone: "",
        youtube: "",
        facebook: "",
        instagram: "",
        tiktok: "",
        createdAt: ""
      },
      rules: {
        displayName: [
          v => !!v || "Display name is required",
          v => (v || "").length >= 5 || "Min 5 characaters"
        ],
        first_name: [v => (v || "").length <= 15 || "Max 15 characaters"],
        middle_name: [v => (v || "").length <= 15 || "Max 15 characaters"],
        last_name: [v => (v || "").length <= 15 || "Max 15 characaters"],
        youtube: [v => {
          if(v){
            return /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(v) || 'Invalid Url'   // eslint-disable-line
          }else return true
        }],
        facebook: [v => {
          if(v){
            return /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(v) || 'Invalid Url'   // eslint-disable-line
          }else return true
        }],
        instagram: [v => {
          if(v){
            return /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(v) || 'Invalid Url'   // eslint-disable-line
          }else return true
        }],
        tiktok: [v => {
          if(v){
            return /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(v) || 'Invalid Url'   // eslint-disable-line
          }else return true
        }]
      },
      statusOptions: ["registered", "active", "deactivated", "blocked"],
      phone: ''
    };
  },
  async mounted() {
    let {data: {payload}} = await User.user(this.$route.params.user_id)
    this.user = _.pick(payload, [
      "status",
      "display_name",
      "email",
      "first_name",
      "middle_name",
      "last_name",
      "phone",
      "youtube",
      "facebook",
      "instagram",
      "tiktok",
      "created_at"
    ]);
    this.phone = phoneHelper.nepaliPhoneFormat(this.user.phone)
  },
  computed: {
    formatDateTime: {
      get() {
        return dateHelper.formatDateTime(this.user.created_at)
      }
    },
    ...mapGetters({
      vg_editObj: "user/editObj",
    })
  },
  methods: {
    back(){
      this.$router.push({name: 'User'})
    },
    formatPhoneNumber() {
        this.user.phone = phoneHelper.flatPhoneFormat(this.phone)
        this.phone = phoneHelper.nepaliPhoneFormat(this.phone)
    },
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await User.update(this.vg_editObj.id, this.user)
          this.snackbar({status: true, message: data.message})
          this.spinner(false)
          this.$router.push({name: 'User'})
        }catch(err){
          console.log(err)
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "User" });
    }
  }
};
</script>