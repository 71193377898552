import Login from '@/views/login/Login';
import Auth from '@/middleware/auth';
import nonAuth from '@/middleware/nonAuth';

export default [
    {
        name: 'Login',
        path: '/login',
        component: Login,
        meta: {
            middleware: {
                ignore: Auth,
                attach: nonAuth
            },
            nav: {
                addToNav: false,
                navText: 'Login',
                header: 'Login',
                navIcon: '',
            }
        }
    }
]