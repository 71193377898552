<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Create Role Group</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">

                  <v-text-field
                    class="mt-2"
                    v-model="role_group.name"
                    :rules="rules.name"
                    hint="Please enter name"
                    label="Name*"
                  ></v-text-field>

                  <v-select
                    class="mt-2"
                    v-model="role_group.view"
                    :items="viewTypes"
                    label="View*"
                    dense
                  ></v-select>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import RoleGroup from '@/api/role_group'


export default {
  data() {
    return {
      role_group: {
        name: "",
        view: ""
      },
      rules: {
        name: [v => !!v || "Name is required"],
        view: [v => !!v || "View is required"]
      },
      viewTypes: [
        {text: 'All', value: 'all'}, 
        {text: 'Mine', value: 'mine'}
      ]
    };
  },
  methods: {
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await RoleGroup.create(this.role_group)
          this.snackbar({status: true, message: data.message})
          this.spinner(false)
          this.$router.push({name: 'RoleGroup'})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "RoleGroup" });
    }
  }
};
</script>