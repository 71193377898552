module.exports = {
    state: 'production',
    development: {
        api_token: process.env.VUE_APP_API_TOKEN,
        auth_url: process.env.VUE_APP_AUTH_URL
    },
    test: {
        api_token: process.env.VUE_APP_API_TOKEN,
        auth_url: process.env.VUE_APP_AUTH_URL
    },
    production: {
        api_token: process.env.VUE_APP_API_TOKEN,
        auth_url: process.env.VUE_APP_AUTH_URL
    }
}