import UserApi from '@/api/user'

export default {
    namespaced: true,
    state: {
        user: {},
        role: {},
        auth_token: '',
        passwordPop: false
    },
    getters: {
        user: state => state.user,
        role: state => state.role,
        auth_token: state => state.auth_token,
        passwordPop: state => state.passwordPop
    },
    mutations: {
        user(state, data) {
            state.user = data
        },
        role(state, data){
            state.role = data
        },
        auth_token(state, data) {
            state.auth_token = data
        },
        passwordPop(state, data){
            state.passwordPop = data
        },
        resetLogin(state) {
            state.user = {}
            state.role = {}
            state.auth_token = ''
            state.passwordPop = false
        }
    },
    actions: {
        async login({ commit }, data) {
            let {data: {payload}} = await UserApi.login(data);
            let roleData = await UserApi.role(payload.auth_token)
            if(roleData.data && roleData.data.payload && roleData.data.payload.role_group){
                commit('auth_token', payload.auth_token);
                commit('user', payload.user);
                commit('role', roleData.data.payload.role_group)
            }
        },
        async user({commit}, userId){
            let {data: {payload}} = await UserApi.user(userId)
            commit('user', payload)
        }
    }
};