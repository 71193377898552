import UserApi from '@/views/api-user';
import ApiUserCreate from '@/views/api-user/create'
import ApiUserEdit from '@/views/api-user/edit'
import ApiUserTrash from '@/views/api-user/trash'

export default [
    {
        name: 'ApiUser',
        path: '/api-user',
        component: UserApi,
        meta: {
            nav: {
                addToNav: false,
                navText: 'Api User',
                header: 'Api User',
                navIcon: 'mdi-account-check',
            }
        }
    },
    {
        name: 'ApiUserCreate',
        path: '/api-user/create',
        component: ApiUserCreate,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Api User > Create',
                navIcon: 'mdi-account-check',
            }
        }
    },
    {
        name: 'ApiUserEdit',
        path: '/api-user/edit',
        component: ApiUserEdit,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Api User > Edit',
                navIcon: 'mdi-account-check',
            }
        }
    },
    {
        name: 'ApiUserTrash',
        path: '/api-user/trash',
        component: ApiUserTrash,
        meta: {
            nav: {
                addToNav: false,
                navText: '',
                header: 'Api User > Trash',
                navIcon: 'mdi-account-check',
            }
        }
    }
]