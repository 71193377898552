var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mt-8 mb-4",attrs:{"shaped":"","elevation":"6"}},[_c('v-card',{staticClass:"primary white--text header-bar-content",staticStyle:{"margin-top":"10px"}},[_c('v-card-title',{staticClass:"px-6"},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){$event.preventDefault();return _vm.back.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-arrow-left-thick")])],1),_c('span',[_vm._v("Role")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-9",attrs:{"dark":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{attrs:{"color":"white black--text","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.create.apply(null, arguments)}}},[_vm._v("Add")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"white black--text","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.editAll.apply(null, arguments)}}},[_vm._v("Edit")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"red darken-4 grey--text text--lighten-2 font-weight-bold","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.trashed.apply(null, arguments)}}},[_vm._v("Trash")])],1)],1),_c('v-card-text',{staticClass:"pt-0 pb-4 text-center"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }