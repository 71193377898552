import { authApi } from '@/api/index.js'
import envData from '@/env.js'
export const env = envData[envData.state];
import store from '@/store'

export default ({
    apis() {
        return authApi({
            method: 'get',
            url: `/api?view=${store.getters['login/role'].view}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    create(data) {
        return authApi({
            method: 'post',
            url: `/api`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    update(id, data) {
        return authApi({
            method: 'patch',
            url: `/api/${id}`,
            data: data,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    delete(id) {
        return authApi({
            method: 'delete',
            url: `/api/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    trash(){
        return authApi({
            method: 'get',
            url: `/api/trash?view=${store.getters['login/role'].view}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    },

    restore(id){
        return authApi({
            method: 'put',
            url: `/api/${id}`,
            headers: {
                'auth_token': store.getters['login/auth_token']
            }
        })
    }
})