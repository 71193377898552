<template>
  <v-form ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <v-card shaped raised class="mt-2">
            <v-card class="primary white--text header-bar-content" style="margin-top:10px;">
              <v-card-title class="mt-5 px-6">
                <span>Edit User Api</span>
                <v-spacer></v-spacer>
              </v-card-title>
            </v-card>
            <v-card-text>
              <v-row>
                <v-col cols="8" offset="2" md="8" offset-md="2">
                  <v-select
                    class="mt-6"
                    v-model="apiUser.api_id"
                    :rules="rules.api_id"
                    :items="apis"
                    item-text="name"
                    item-value="id"
                    label="API Name*"
                    dense
                  ></v-select>

                  <v-select
                    class="mt-6"
                    v-model="apiUser.role_group_id"
                    :rules="rules.role_group_id"
                    :items="roleGroups"
                    item-text="name"
                    item-value="id"
                    label="Role Group*"
                    dense
                  ></v-select>

                  <v-autocomplete
                    v-model="apiUser.user_id"
                    :items="users"
                    :loading="isLoading"
                    :search-input.sync="searchUser"
                    :rules="rules.user_id"
                    hide-no-data
                    hide-selected
                    item-text="email"
                    item-value="id"
                    label="User*"
                    placeholder="Type email address"
                  ></v-autocomplete>

                  <v-select
                    class="mt-6"
                    v-model="apiUser.ip_rule"
                    :rules="rules.ip_rule"
                    :items="ipRules"
                    label="IP Rule*"
                    dense
                  ></v-select> 

                  <v-text-field
                    class="mt-2"
                    v-model="apiUser.ip_address"
                    hint="Please enter ip address"
                    label="IP Address"
                  ></v-text-field>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="mt-5 px-5" right rounded @click="cancel">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" class="mt-5 px-5" right rounded @click="save">Save</v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import _ from 'lodash'
import ApiUser from '@/api/api_user'
import Api from '@/api/api'
import RoleGroup from '@/api/role_group'
import User from '@/api/user'
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      apiUser: {
        api_id: null,
        role_group_id: null,
        role_group_name: '',
        user_id: null,
        email: null,
        ip_rule: null,
        ip_address: null
      },
      rules: {
        api_id: [v => !!v || "Api is required"],
        role_group_id: [v => !!v || "Role group is required"],
        user_id: [v => !!v || "User is required"],
        ip_rule: [v => !!v || "IP rule on is required"],
      },
      apis: [],
      roleGroups: [],
      users: [],
      ipRules: ['all', 'allow', 'restrict'],
      isLoading: false,
      searchUser: '',
    };
  },
  async mounted(){
    this.spinner(true)
    this.apiUser.ip_rule = this.vg_editObj.ip_rule
    this.apiUser.ip_address = this.vg_editObj.ip_address
    this.apiUser.api_id = (this.vg_editObj.api)?this.vg_editObj.api.id:null
    this.apiUser.role_group_id = (this.vg_editObj.role_group)?this.vg_editObj.role_group.id:null
    if(this.vg_editObj.user){
      this.apiUser.user_id = this.vg_editObj.user.id
      this.apiUser.email = this.findUser(this.apiUser.user_id)
      this.users = [this.vg_editObj.user]
    }

    let {data} = await Api.apis()
    this.apis = data.payload
    let roleData = await RoleGroup.list()
    this.roleGroups = roleData.data.payload
    this.apiUser.role_group_name = this.findRoleGroup(this.apiUser.role_group_id)

    this.spinner(false)
  },
  watch: {
    searchUser: {
      handler: _.debounce(async function(val){
        try{
          if(val){
            this.isLoading = true
            let {data: {payload}} = await User.users(`search=${val}&self=true&page=1`)
            this.users = payload.rows
            this.isLoading = false
          }
        }catch(err){
          console.log(err)
          this.isLoading = true
        }
      }, 900),
      deep: true
    },
    'apiUser.user_id'(userId){
      this.apiUser.email = this.findUser(userId)
    },
    'apiUser.role_group_id'(roleGroupId){
      this.apiUser.role_group_name = this.findRoleGroup(roleGroupId)
    }
  },
  computed: {
    ...mapGetters({
      vg_editObj: "api_user/editObj",
    })
  },
  methods: {
    findUser(userId){
      let currentUser = this.users.find(user => user.id==userId)
      if(currentUser?.email){
        return currentUser.email
      }
      return null
    },
    findRoleGroup(roleGroupId){
      let roleGroup = this.roleGroups.find(roleGroup => roleGroup.id==roleGroupId)
      if(roleGroup?.name){
        return roleGroup.name
      }
      return null
    },
    async save() {
      if (this.$refs.form.validate()) {
        try{
          this.spinner(true)
          let {data} = await ApiUser.update(this.vg_editObj.id ,this.apiUser)
          this.snackbar({status: true, message: data.message})
          this.spinner(false)
          this.$router.push({name: 'ApiUser'})
        }catch(err){
          this.snackbar({status: true, message:err.response.data.message})
          this.spinner(false)
        }
      }
    },
    cancel() {
      this.$router.push({ name: "ApiUser" });
    }
  }
};
</script>